/* eslint-disable max-len */

import memoizeWith from 'ramda/es/memoizeWith'

export const IS_BROWSER = typeof window !== 'undefined'
export const WEBKIT_REGEXP = /WebKit/i
export const IOS_USER_AGENT_REGEXP = /iP(ad|od|hone)/i
export const MOBILE_REGEXP = /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile/i
export const APPLE_PLATFORM = 'MacIntel'

const keyFn = (): string => IS_BROWSER ? window.navigator.userAgent : ''

const _isWebkit = (userAgent: string): boolean => WEBKIT_REGEXP.test(userAgent)
export const isWebkit = memoizeWith(keyFn, _isWebkit)

/**
 * @see https://forums.developer.apple.com/thread/119186
 * @see https://stackoverflow.com/questions/58019463/
 * @see https://gist.github.com/dalethedeveloper/1503252
 */
const _isiOS = (navigator: typeof window.navigator): boolean =>
  IS_BROWSER && (
    IOS_USER_AGENT_REGEXP.test(navigator.userAgent)
      || (navigator.platform === APPLE_PLATFORM && navigator.maxTouchPoints > 1)
  )
export const isiOS = memoizeWith(keyFn, _isiOS)

const _isMobile = (
  navigator: typeof window.navigator
): boolean => IS_BROWSER && MOBILE_REGEXP.test(navigator.userAgent)
export const isMobile = memoizeWith(keyFn, _isMobile)

/**
 * @see https://gist.github.com/gaboratorium/25f08b76eb82b1e7b91b01a0448f8b1d
 */
const _isIE = (navigator: typeof window.navigator): number | false => {
  if (!IS_BROWSER) {
    return false
  }

  const ua = navigator.userAgent

  const msie = ua.indexOf('MSIE ')
  if (msie > 0) {
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10)
  }

  const trident = ua.indexOf('Trident/')
  if (trident > 0) {
    const rv = ua.indexOf('rv:')
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10)
  }

  const edge = ua.indexOf('Edge/')
  if (edge > 0) {
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10)
  }

  return false
}
export const isIE = memoizeWith(keyFn, _isIE)

export const isUnsupportedIE = (
  navigator: typeof window.navigator,
  version: number,
): boolean => {
  const current = isIE(navigator)
  return typeof current === 'number' && current < version
}
