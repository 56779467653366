/* eslint-disable max-len */

import './index.scss'
import openInEdge from '@whispli/browser/navigator/open-in-edge'
import { render } from './render'

if (!document.getElementById('app')?.childElementCount) {
  render()
  openInEdge()
}

if (import.meta.hot) {
  import.meta.hot.accept()
}
