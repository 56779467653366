import '@whispli/loading';

(async () => {
  const [
    { createApp },
    { default: init },
  ] = await Promise.all([
    import('@whispli/ui'),
    import('@/init'),
  ])

  await createApp(init)
})()

if (import.meta.hot) {
  import.meta.hot.accept()
}
