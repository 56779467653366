import { IS_BROWSER, isUnsupportedIE } from './'

const EDGE_PROTOCOL = 'microsoft-edge'

export default (
  errorSelector: string = '#app__error',
  _buttonSelector: string = '#app__error__open-in-edge',
  textSelector: string = '#app__error__text',
  _userAgentSelector: string = '#app__error__user-agent',
  loadingSelector: string = '[id^=app__loading__]',
) => {
  if (!IS_BROWSER) {
    return
  }

  const dead = isUnsupportedIE(window.navigator, 12)
  if (!dead) {
    return true
  }

  const nodeList = document.querySelectorAll(loadingSelector)
  for (let i = 0; i < nodeList.length; ++i) {
    (nodeList[i] as HTMLElement).style.display = 'none'
  }

  (document.querySelector(errorSelector) as HTMLElement)!.style.display = 'block'
  // ;(document.querySelector(userAgentSelector) as HTMLElement)!.innerText = navigator.userAgent
  // const button = (document.querySelector(buttonSelector) as HTMLElement)!
  // button.style.display = 'inline'
  // button.addEventListener('click', openInEdge.bind(null, textSelector))

  'stop' in window
    ? window.stop()
    : document.execCommand('Stop')

  try {
    openInEdge(textSelector)
  } catch (err) {
    console.error(err) // eslint-disable-line no-console
  }
}

const openInEdge = (selector: string) => navigator['msLaunchUri'](
  `${EDGE_PROTOCOL}:${window.location.href}`,
  () => onSuccess(selector),
  () => onError(selector),
)

const onError = (selector) =>
  (document.getElementById(selector) as HTMLElement)!.innerHTML =
    '<a href="https://microsoft.com/windows/microsoft-edge" target="_blank">Download Microsoft Edge</a>'

const onSuccess = (selector) =>
  (document.getElementById(selector) as HTMLElement)!.innerHTML = 'Opened in Edge'
